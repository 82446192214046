<template>
  <div :class="{ 'container-fluid': !compactMode }">
    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error" class="my-3"></ErrorMessage>

    <div v-if="!isLoading && !error">
      <div v-if="!compactMode" class="row">
        <div class="col">
          <div class="my-3">
            <form
              @submit.prevent
              v-if="!isLoading"
              class="d-flex align-items-center bg-dark p-2 rounded"
            >
              <div class="form-floating-cta-container">
                <div class="form-floating-cta">
                  <fa-icon
                    @click="resetFilter"
                    v-if="keyword.length"
                    :icon="['fas', 'times-circle']"
                    role="button"
                  ></fa-icon>
                </div>
                <input
                  v-model.trim="keyword"
                  ref="keyword"
                  type="text"
                  class="form-control form-control-dark"
                  placeholder="Search metric..."
                >
              </div>
              <IconButton
                @click="fetchMetric"
                :icon="'sync-alt'"
                class="btn btn-primary ms-2"
              ></IconButton>
            </form>

            <div v-if="!isLoading" class="rounded border bg-white mt-2">
              <div class="table-responsive">
                <table class="table m-0">
                  <thead>
                    <tr>
                      <th scope="col" class="align-baseline">Metric</th>
                      <th scope="col" class="align-baseline">Last entry</th>
                      <th scope="col" class="align-baseline">Last updated</th>
                      <th scope="col" class="align-baseline">Target</th>
                      <th scope="col" class="align-baseline">Difference</th>
                      <th scope="col" class="align-baseline"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!filteredItems.length">
                      <td colspan="6">No metrics found</td>
                    </tr>
                    <tr
                      v-else
                      v-for="(metric, metricIndex) in filteredItems"
                      :key="`key-${metricIndex}`"
                    >
                      <!-- NAME -->
                      <td class="align-middle">{{ metric.name }}</td>

                      <!-- LAST ENTRY VALUE -->
                      <td
                        :class="{ 'is-busy': !metric.value }"
                        class="align-middle small"
                      >
                        {{ metric.value || 'n/a' }}{{ metric.value ? metric.unit : '' }}
                      </td>

                      <!-- LAST ENTRY DATE -->
                      <td
                        :class="{ 'is-busy': !metric.createdAt }"
                        class="align-middle small"
                      >
                        <div>{{ formatDate(metric.createdAt) }}</div>
                        <div
                          v-if="metric.createdAt"
                          class="smaller text-muted text-nowrap"
                        >{{ formatDateRelative(metric.createdAt) }}</div>
                      </td>

                      <!-- TARGET -->
                      <td
                        :class="{ 'is-busy': !metric.target }"
                        class="align-middle small"
                      >{{ metric.target || 'not set' }}{{ metric.target ? metric.unit : '' }}</td>

                      <!-- DIFFERENCE -->
                      <td class="align-middle small">
                        <span
                          v-if="offsetFromTarget(metric.value, metric.target)"
                          :class="offsetFromTarget(metric.value, metric.target).className"
                        >
                          {{ offsetFromTarget(metric.value, metric.target).value }}{{ metric.unit }}
                        </span>
                        <span v-else>&mdash;</span>
                      </td>

                      <!-- PROGRESS -->
                      <td class="align-middle text-end">
                        <IconButton
                          @click="showHistory(metric.metricId)"
                          :icon="'chart-line'"
                          :text="'History'"
                          class="btn btn-sm btn-primary"
                        ></IconButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="row">
        <div class="col">
          <div
            :class="{ 'is-busy': isLoading, 'mb-3': compactMode, 'my-3': !compactMode }"
            class="d-flex align-items-center"
          >
            <div class="input-group flex-grow-1 me-2">
              <input
                v-model.trim="keyword"
                ref="keyword"
                type="text"
                class="form-control"
                placeholder="Search metric..."
              >
              <button
                @click="resetFilter"
                v-if="keyword.length"
                class="btn btn-sm btn-primary"
              >Reset</button>
            </div>
            <div class="input-group flex-shrink-0 w-auto">
              <IconButton
                @click="fetchMetric"
                :icon="'sync-alt'"
                class="btn btn-primary"
              ></IconButton>
            </div>
          </div>

          <LoadingMessage v-if="isLoading"></LoadingMessage>

          <div v-if="!isLoading && !error">
            <div v-if="!filteredItems.length">No metrics found</div>
            <div
              v-else
              v-for="(metric, metricIndex) in filteredItems"
              :key="`key-${metricIndex}`"
              class="card mb-3"
            >
              <div class="card-body">
                <div class="card-title d-flex align-items-center">
                  <div class="h5 flex-grow-1 mb-0 me-3">{{ metric.name }}</div>
                  <IconButton
                    @click="showHistory(metric.metricId)"
                    v-if="true || metric.createdAt"
                    :icon="'chart-line'"
                    :text="'History'"
                    class="btn btn-sm btn-primary"
                  ></IconButton>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex align-items-baseline">
                    <div class="flex-grow-1 me-3">Last record</div>
                    <div :class="{ 'is-busy': !metric.value }" class="text-end">
                      {{ metric.value || 'n/a' }}{{ metric.createdAt ? metric.unit : '' }}
                      <div
                        v-if="metric.createdAt"
                        class="smaller text-muted text-nowrap"
                      >{{ formatDateRelative(metric.createdAt) }}</div>
                    </div>
                  </li>
                  <li class="list-group-item d-flex align-items-baseline">
                    <div class="flex-grow-1 me-3">Target</div>
                    <div :class="{ 'is-busy': !metric.target }" class="text-end">
                      {{ metric.target || 'not set' }}{{ metric.target ? metric.unit : '' }}
                    </div>
                  </li>
                  <li class="list-group-item d-flex align-items-baseline">
                    <div class="flex-grow-1 me-3">Difference</div>
                    <div
                      v-if="offsetFromTarget(metric.value, metric.target)"
                      :class="offsetFromTarget(metric.value, metric.target).className"
                      class="text-end"
                    >
                      {{ offsetFromTarget(metric.value, metric.target).value }}{{ metric.unit }}
                    </div>
                    <div v-else class="text-end">&mdash;</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <portal to="modal">
      <transition name="modal">
        <MetricHistoryModal
          @close="hideHistory"
          v-model="showMetricHistoryModal"
          v-if="showMetricHistoryModal"
          :metricId="activeMetricId"
          :userId="userId"
        ></MetricHistoryModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';

export default {
  name: 'ClientMetric',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    MetricHistoryModal: () => import('@/components/modal/MetricHistoryModal'),
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    compactMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredItems() {
      const { keyword } = this;
      if (keyword.length) {
        const q = keyword.toLowerCase();
        return this.userMetrics.filter((metric) => {
          const { name } = metric;
          return name.toLowerCase().indexOf(q) >= 0;
        });
      }
      return this.userMetrics;
    },
  },
  methods: {
    async fetchMetric() {
      this.isLoading = true;
      this.keyword = '';
      try {
        const { data } = await httpGet(`/metric/list/${this.userId}`);
        this.userMetrics = data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(d) {
      if (!d) return 'n/a';
      return this.$options.filters.dateReadable(d);
    },
    formatDateRelative(d) {
      return this.$options.filters.utcDateRelative(d);
    },
    offsetFromTarget(n, target) {
      if (!n || !target) return null;

      const x = (n - target).toFixed(3);
      let prefix = '';
      let className = '';
      if (x < 0) {
        prefix = '-';
        className = 'text-danger';
      } else if (x > 0) {
        prefix = '+';
        className = 'text-success';
      }

      if (x === 0) return null;
      return {
        value: `${prefix}${Math.abs(x)}`,
        className,
      };
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
    showHistory(metricId) {
      this.activeMetricId = metricId;
      this.showMetricHistoryModal = true;
    },
    hideHistory() {
      this.showMetricHistoryModal = false;
      this.activeMetricId = null;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      keyword: '',
      userMetrics: [],
      activeMetricId: null,
      showMetricHistoryModal: false,
    };
  },
  mounted() {
    this.fetchMetric();
  },
};
</script>

<style lang="scss" scoped>
th[scope="col"] {
  &:nth-child(1) {
    width: 45%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 10%;
  }
  &:nth-child(5) {
    width: 5%;
  }
  &:nth-child(6) {
    width: 10%;
  }
}
</style>
